<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑物料单位" : "新增物料单位" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item prop="name" label="单位名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="english_name" label="英文名称">
            <a-input v-model="form.english_name" />
          </a-form-model-item>
          <a-form-model-item prop="is_main" label="是否是主单位">
            <a-radio-group v-model="form.is_main" @change="changeIsMain">
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false"> 否 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-if="!form.is_main" prop="main_unit" label="主单位">
            <a-select v-model="form.main_unit" :allowClear="true">
              <a-select-option v-for="item of unitItems" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="!form.is_main" prop="conversion_ratio" label="换算比例">
            <a-input-number v-model="form.conversion_ratio" :min="0" style="width: 100%" />
          </a-form-model-item>

          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.remark" allowClear />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { goodsUnitUpdate, goodsUnitCreate } from "@/api/goods";
import { goodsUnitOption } from "@/api/option";

export default {
  name: "FormModal",
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "请输入单位名称", trigger: "change" },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },
        ],
      },
      loading: false,

      unitItems: [],
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? goodsUnitUpdate : goodsUnitCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
    changeIsMain(event) {
      if (event.target.value) {
        this.form.main_unit = null;
        this.form.conversion_ratio = null;
        console.log(this.form)
      }
    },
  },
  watch: {
    visible(status) {
      if (status) {
        goodsUnitOption({ page_size: 999999, is_active: true, is_main: true }).then((resp) => {
          this.unitItems = resp.results;
        });
      }
    },
  },
};
</script>

<style scoped></style>
